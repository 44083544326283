/* eslint-ignore jsx-a11y/label-has-for */

import React from "react";
import PropTypes from "prop-types";

const defaultProps = {
  name: "",
  type: "text",
  errors: [],
  required: false,
  children: undefined,
};

const Input = ({ label, errors, required, children, ...props }) => {
  const hasErrors = errors && errors.length > 0;

  return (
    <div className={`form-element${hasErrors ? " field_with_errors" : ""}`}>
      <label htmlFor={props.name} className="form-input-label">
        {label} {required ? <abbr title="Obrigatório">*</abbr> : ""}
      </label>

      {children || (
        <input
          id={props.name}
          className="form-input"
          required={required}
          {...props}
        />
      )}

      {hasErrors && (
        <span className="form-input-error-message">{errors.join(", ")}</span>
      )}
    </div>
  );
};

Input.defaultProps = defaultProps;
Input.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  type: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  required: PropTypes.bool,
  children: PropTypes.node,
};

export default React.memo(Input);
