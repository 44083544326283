import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

const TimezoneGuesserInput = ({ name }) => (
  <input type="hidden" name={name} value={moment.tz.guess()} readOnly />
);

TimezoneGuesserInput.propTypes = {
  name: PropTypes.string.isRequired,
};

export default TimezoneGuesserInput;
