import React from "react";
import PropTypes from "prop-types";
import Flatpickr from "react-flatpickr";
import { Portuguese } from "flatpickr/dist/l10n/pt";

const defaultProps = {
  className: "",
  defaultValue: "",
  placeholder: "",
  required: false,
};

function DateRangeInput(props) {
  function handleDateRangeChange(_, dateStr, instance) {
    instance.element.value = dateStr.replace("até", "-");
  }

  return (
    <div className="relative">
      <Flatpickr
        className={`form-input cursor-pointer ${props.className}`}
        id={props.name}
        name={props.name}
        options={{
          defaultDate: props.defaultValue,
          dateFormat: "Y-m-d",
          altInput: true,
          altFormat: "d/m/Y",
          locale: Portuguese,
          mode: "range",
        }}
        placeholder={props.placeholder}
        required={props.required}
        onReady={handleDateRangeChange}
        onChange={handleDateRangeChange}
      />

      <div className="absolute inset-y-0 right-0 flex items-center pr-3 text-slate-400 pointer-events-none">
        <iconify-icon icon="ri:calendar-line" />
      </div>
    </div>
  );
}

DateRangeInput.defaultProps = defaultProps;
DateRangeInput.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.array,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
};

export default DateRangeInput;
