const ReactRailsUJS = require("react_ujs");

export default function reactMountHandler(componentRequireContext) {
  ReactRailsUJS.useContext(componentRequireContext);

  // Prevent double mount on page load
  ReactRailsUJS._firstTurboLoadSkipped = false;
  ReactRailsUJS.handleEvent("turbo:load", () => {
    if (ReactRailsUJS._firstTurboLoadSkipped) ReactRailsUJS.handleMount();
    ReactRailsUJS._firstTurboLoadSkipped = true;
  });

  // Unmount components and call cleanup functions after Turbo navigations
  ReactRailsUJS.handleEvent("turbo:before-render", (e) => {
    if (e.target) {
      ReactRailsUJS.unmountComponents(e.target);
    }
  });

  ReactRailsUJS.handleEvent("turbo:frame-load", ReactRailsUJS.handleMount);
  ReactRailsUJS.handleEvent("turbo:frame-render", ReactRailsUJS.handleUnmount);
}
