import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

const defaultProps = {
  clearable: false,
  placeholder: "Selecione uma opção",
  defaultValue: "",
  options: [],
  value: undefined,
  onChange: undefined,
  required: false,
};

function SelectInput(props) {
  function getDefaultOption() {
    const { options, defaultValue } = props;

    if (defaultValue) {
      return options.find((option) => option.value === defaultValue);
    }

    return null;
  }

  return (
    <div className="form-element">
      <label htmlFor={props.name} className="form-input-label">
        {props.label}{" "}
        {props.required ? <abbr title="Obrigatório">*</abbr> : null}
      </label>

      <Select
        id={props.name}
        name={props.name}
        value={props.value}
        defaultValue={getDefaultOption()}
        classNamePrefix="custom-select"
        placeholder={props.placeholder}
        options={props.options}
        onChange={props.onChange}
        isClearable={props.clearable}
        required={props.required}
        noOptionsMessage={() => "Nenhuma opção encontrada"}
      />
    </div>
  );
}

SelectInput.defaultProps = defaultProps;
SelectInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  clearable: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

export default SelectInput;
