document.addEventListener("DOMContentLoaded", bindActionToCloseAlerts);
document.addEventListener("turbo:load", bindActionToCloseAlerts);

function bindActionToCloseAlerts() {
  document.querySelectorAll(".system-alert-close").forEach((button) => {
    button.addEventListener("click", (event) => {
      const alert = event.target.closest(".system-alert");
      alert.remove();
    });
  });
}
