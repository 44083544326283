import React, { Component } from "react";
import PropTypes from "prop-types";
import CurrencyInput from "react-intl-currency-input";

const defaultProps = {
  currency: "BRL",
  value: 0,
  hint: undefined,
};

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

class FormCurrencyInput extends Component {
  state = {
    value: this.props.value,
  };

  onChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { value } = this.state;
    const { name, hint } = this.props;

    return (
      <div className="form-element">
        <label htmlFor={name} className="form-input-label">
          {this.props.label}
        </label>

        <CurrencyInput
          type="text"
          id={name}
          className="form-input"
          currency={this.props.currency}
          config={currencyConfig}
          defaultValue={value}
          onChange={this.onChange}
          inputmode="decimal"
          required
        />

        {hint && <span className="form-input-hint-message">{hint}</span>}

        <input type="hidden" name={this.props.name} value={value} />
      </div>
    );
  }
}

FormCurrencyInput.defaultProps = defaultProps;
FormCurrencyInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  currency: PropTypes.string,
  hint: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default FormCurrencyInput;
