import React from "react";
import MaskedInput from "react-text-mask";
import Input from "./Input";

const CPF_MASK = [
  /\d/,
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
];

export default function CPFInput(props) {
  return (
    <Input label={props.label} errors={props.errors} required={props.required}>
      <MaskedInput
        key="cpf"
        type="text"
        name={props.name}
        className="form-input"
        defaultValue={props.defaultValue}
        data-lpignore="true"
        guide={false}
        mask={CPF_MASK}
        required={props.required}
      />
    </Input>
  );
}
