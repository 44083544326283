import React from "react";
import MaskedInput from "react-text-mask";

import Input from "./Input";

const PHONE_MASK = [
  "(",
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export default function PhoneInput(props) {
  return (
    <Input label={props.label} errors={props.errors} required={props.required}>
      <MaskedInput
        type="text"
        name={props.name}
        className="form-input"
        defaultValue={props.defaultValue}
        data-lpignore="true"
        guide={false}
        mask={PHONE_MASK}
        placeholder="(  )     -  "
        required={props.required}
      />
    </Input>
  );
}
