/* eslint no-console:0 */
import Rails from "@rails/ujs";
import ReactMountHandler from "./utils/reactMountHandler";

import "@hotwired/turbo-rails";

import "./scripts/alerts";
import "./stylesheets/devise.css";

const componentRequireContext = require.context("components/inputs", true);

ReactMountHandler(componentRequireContext);
Rails.start();
